import Vue from 'vue'
import Vuex from 'vuex'
import { db, auth } from "../firebase/firebaseInit";
import { collection, doc, getDoc, updateDoc, getDocs, orderBy, deleteDoc } from "firebase/firestore";
import 'core-js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    blogPosts: [],
    postLoaded: null,
    blogHTML: "Write your blog title here...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null, 
    blogPhotoPreview: null,
    editPost: null,
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
    
  },
  getters:{
    blogPostFeed(state){
      return state.blogPosts.slice(0,2);
    },
    blogPostCards(state){
      return state.blogPosts.slice(2,7);
    }
  },
  mutations: {

    newBlogPost(state,payload){
      state.blogHTML = payload;
    },
    updateBlogTitle(state, payload){
      state.blogTitle = payload;
    },
    fileNameChange(state, payload){
      state.blogPhotoName = payload;
      console.log(state.blogPhotoName);
    },
    createFileURL(state, payload){
      state.blogPhotoFileURL = payload; 
      console.log(state.blogPhotoFileURL);
    },
    openPhotoPreview(state){
      state.blogPhotoPreview = !state.blogPhotoPreview; 
      console.log("Preview",state.blogPhotoPreview);
    },
    toggleEditPost(state, payload) {
      state.editPost = payload;
    },
    setBlogState(state, payload){
      state.blogTitle === payload.blogTitle;
      //console.log("state.blogTitle",payload.blogTitle);
      state.blogHTML === payload.blogHTML;
     // console.log("state.blogHTML",payload.blogHTML);
      state.blogPhotoFileURL === payload.blogCoverPhoto;
     // console.log("state.blogPhotoFileURL",payload.blogCoverPhoto);
      state.blogPhotoName === payload.blogCoverPhotoName;
     // console.log("blogPhotoName",payload.blogCoverPhotoName);
    },
    filterBlogPost(state, payload){
      state.blogPosts = state.blogPosts.filter(post => post.blogID !== payload);
    },
    updateUser(state, payload) {
      state.user = payload;
    },
    setProfileAdmin( state, payload){
      state.profileAdmin = payload;
      console.log("Admin state:",state.profileAdmin);
    },
    setProfileInfo(state, doc) {

      state.profileEmail = doc.data().email;
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileUsername = doc.data().username;
    },
    setProfileInitials(state) {

      state.profileInitials = state.profileFirstName.match(/^./).join("") + state.profileLastName.match(/^./).join("");

      //  state.profileFirstName.match(/(\b\S)?/g).join("") +
      //  state.profileLastName.match(/(\b\S)?/g).join("");
      console.log("Inititals:", state.profileInitials);
    },
    changeFirstName(state, payload) {
      state.profileFirstName = payload;
      console.log(state.profileFirstName);
    },
    changeLastName(state, payload) {
      state.profileLastName = payload;
      console.log(state.profileLastName);
    },
    changeUsername(state, payload) {
      state.profileUsername = payload;
    },
  },
  actions: {

    async getCurrentUser({ commit }, user) {
      /*Use of commit to do the setProfileInitialschange of the state in the mutations*/

      var userIdFb = auth.currentUser.uid;
      const docRef = doc(db, "users", userIdFb);
      const docSnap = await getDoc(docRef);

      commit("setProfileInfo", docSnap);
      console.log("Document data:", docSnap.data());
      commit("setProfileInitials");

      const token = await user.getIdTokenResult();
      console.log("token claims",token.claims);

      //set admin with true or false
      const admin = token.claims.admin;
      commit("setProfileAdmin", admin);

    },
    async getPost({state}) {

     const querySnapshot = await getDocs(collection(db, "Post"), orderBy("date", "desc"));

     querySnapshot.forEach((doc) => {
      if(!state.blogPosts.some((post) => post.blogID === doc.id)) {
        
        console.log("Get data");

        const data = {
          blogID: doc.data().blogID,
          blogHTML: doc.data().blogHTML,  
          blogCoverPhoto: doc.data().blogCoverPhoto,
          blogTitle: doc.data().blogTitle,
          blogDate: doc.data().date,
          blogCoverPhotoName: doc.data().blogCoverPhotoName,
       //   profileId:doc.data().profileId,

        };
        state.blogPosts.push(data);
         console.log("blogTitle",doc.data().blogTitle);
        console.log("blogCoverPhoto", doc.data().blogCoverPhoto);
        
      }
    //  console.log(doc.id, " Docs in the If => ", doc.data());
      });
      state.postLoaded = true;
     //  console.log("state blogPosts",state.blogPosts);
    }, //funcion
    async updatePost({commit, dispatch}, payload){
      commit('filterBlogPost',payload);
      await dispatch("getPost");
    console.log("Update Post",payload);
    },

  async deletePost({commit}, payload){
    
    await deleteDoc(doc(db, "Post", payload));
    commit('filterBlogPost',payload);
    console.log("Post Deleted",payload);

  },

    async updateUserSettings({ commit, state }) {
      
      var userIdFb = auth.currentUser.uid;
      console.log("userIdFb:",userIdFb);
      const docRef = doc(db, "users", userIdFb);
     
      // Set the new data from user loged in
      //const docSnap 
      await updateDoc(docRef, {
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
        email: state.profileEmail,
      });
      
      commit("setProfileInitials");
      console.log("UserID", state.profileId);
      console.log("Data updated for FN", state.profileFirstName);
      console.log("Data updated for LN", state.profileLastName,);
      console.log("Data updated for UN", state.profileUsername);
      console.log("Email remainds the same", state.profileEmail);


    },
  },
  modules: {
  }
});