<template>
  <footer>
    <div class="container">
      <div class="left">
        <div class="col-1">
          <router-link class="header" :to="{ name: 'Home' }"
            >FireBlogs</router-link
          >
          <ul class="col-1.ul">
            <li class="col-1.li">
              <a href="#"><youTube class="svg-icon" /></a>
            </li>
            <li class="col-1.li">
              <a href="#"><twitter class="svg-icon" /></a>
            </li>
            <li>
              <a href="#"><instagram class="svg-icon" /></a>
            </li>
            <li class="col-1.li">
              <a href="#"><linkedin class="svg-icon" /></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2">
        <ul class="col-2.ul">
          <router-link class="col-2-link" :to="{ name: 'Home' }"
            >Home</router-link>
          <router-link class="col-2-link" :to="{ name: 'Blogs' }"
            >Blogs</router-link>
          <router-link v-if="admin" class="col-2-link" :to="{ name: 'CreatePost'}">Create Post</router-link>
          <router-link v-if="!user" class="col-2-link" :to="{ name: 'Login' }"
            >Login in/Register</router-link>
        </ul>
      </div>
      <div class="right">
        <p>Copyright 2022 All Rigths Reserverd Traversy Media</p>
      </div>
    </div>
  </footer>
</template>

<script>
import youTube from "../assets/Icons/youtube-brands.svg";
import twitter from "../assets/Icons/twitter-brands.svg";
import instagram from "../assets/Icons/instagram-brands.svg";
import linkedin from "../assets/Icons/linkedin-brands.svg";

export default {
  name: "footer-vue",
  components: {
    youTube,
    twitter,
    instagram,
    linkedin,
  },
  computed:{
    user(){
      console.log(this.$store.state.user);
      return this.$store.state.user;
    },
    admin(){
      return this.$store.state.profileAdmin;
    },
  }
};
</script>

<style scope>
footer {
  margin-top: auto;
  padding: 100px 25px;
  background-color: #303030;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (mind-windth: 800px) {
  .container{
    flex-direction: row;
    gap: 0px
  }
}


div {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.left {
  gap: 32px;
  color: #303030;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 800px) {
  .left {
    flex-direction: row;
    align-items: initial;
    gap: 0px;
  }
}

.header {
  text-align: center;
  font-size: 24px;
  color: #303030;
  text-decoration: none;
  font-weight: 600;
}

@media (min-width: 800px) {
 .header{
   text-align: initial;
 }
}

ul  {
  gap: 16px;
  list-style: none;
  display: flex;
}

.col-1 {
  gap: 32px;
  display: flex;
  flex: 1;
}

 @media (min-width: 800px) {
   .col-1{
     gap: 0;
    }
  }

.col-2 {
  gap: 32px;
  display: flex;
  flex: 1;
}

@media (min-width: 800px) {
  .col-2{
     gap: 0;
  }
}

.col-1 {
  flex-direction: column;
  text-align: center;
}

  @media (min-width: 800px) {
    h2{
       text-align: initial;
    }
  }

.col-1.ul {
  margin-top: auto;
}

.col-1.li {
  display: flex;
  align-items: center;
  width: 24px;
  height: auto;
  color: rgb(248, 250, 250);
}
.svg-icon {
  display: flex;
  align-items: center;
  width: 24px;
  height: auto;
  color: #303030;
}

.col-2.ul {
  height: 100%;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 800px) {
  .col-1.ul {
    flex-direction: column;
  }
}

.col-2-link {
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 500;
  color: rgb(214, 211, 207);
  text-decoration: none;
}

.right {
  gap: 32px;
  color: #303030;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 800px) {
  .right {
    align-items: flex-end;
    gap: 0;
  }
}

p {
  margin-top: auto;
}
</style>